import anime from "animejs";
import { Button } from "antd";
import { Page } from "components/Contents";
import { ChoiceButton } from "components/Html";
import {
  relativeToPageIndex,
  Scroller,
  ScrollerContext,
  getPageIndex,
} from "components/Scroller/Scroller";
import { useContext, useEffect, useRef, useState } from "react";
import TextTransition from "react-text-transition";
import { ScrollStatus } from "smooth-scrollbar/interfaces";
import { getRandomColor } from "utils/maths";

const TITLES = [
  "Scroll...",
  "ça c'est une balise '<></>'",
  "<nom>contenu</nom>",
  "Exemple :",
  "<title>Ceci est un titre</title>",
  "On a aussi des balises image",
  '<img src="la source de l\'image">',
  "Exemple :",
  "<img src='falaise.jpg'>",
];

export const Cover = () => {
  const [title, setTitle] = useState(TITLES[0]);
  const [step, setStep] = useState(0);
  const [rickRolled, setRickRolled] = useState(false);
  const { initScroller } = useContext(ScrollerContext);
  const [img, setImg] = useState("./images/riff.jpeg");
  const videoRef = useRef<HTMLVideoElement>(null);
  const [buttonText, setButtonText] = useState("Suivant →");

  const handlePlay = () => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  };

  const handlePause = () => {
    if (videoRef.current) {
      videoRef.current.pause();
    }
  };

  useEffect(() => {
    if (rickRolled) handlePlay();
  }, [rickRolled]);

  const handleAnime = (e: ScrollStatus) => {
    const scrollYPagePosition = relativeToPageIndex(e, 0);
    const pageIndex = Number(getPageIndex(e));
    if (pageIndex < TITLES.length - 1) {
      setTitle(TITLES[Number(pageIndex.toFixed(0))]);
      setStep(Number(pageIndex.toFixed(0)));
    }
    if (pageIndex > 9.3) return;
    anime({
      targets: "#main-title",
      translateY: scrollYPagePosition,
      easing: "linear",
      duration: 0,
    });
  };
  useEffect(() => {
    const scroller = initScroller();
    scroller && scroller.on("scroll", handleAnime);
  }, []);
  const handleChoice = (choice: string) => {
    setImg("./images/rickroll.jpg");
    setButtonText("Oups.");
    setRickRolled(true);
    setTimeout(() => {
      setButtonText("Bisous.");
    }, 3000);
  };
  return (
    <>
      <Page>
        <div className="flex-center" style={{ padding: "30px" }}>
          <TextTransition>
            <h2
              id="main-title"
              style={{ textAlign: "center" }}
              className="gradient-emerald-text"
            >
              {title}
            </h2>
          </TextTransition>
        </div>
      </Page>
      {TITLES.map(
        (_, i) => i < TITLES.length && <Page color={"transparent"} />
      )}
      <Page>
        <div className="flex-center flex-wrap">
          <div className={"choice-button flex-center flex-column fixed"}>
            <div
              className="image-bg fixed"
              style={{ backgroundImage: `url("${img}")` }}
            />
          </div>
          <ChoiceButton
            noHoverAnim
            onClick={() => {
              handleChoice("rickroll");
            }}
          >
            {buttonText}
          </ChoiceButton>
        </div>
        <div style={{ display: "none" }}>
          <video
            ref={videoRef}
            controls={false}
            src="/videos/rickroll.mp4"
          ></video>
        </div>
      </Page>
    </>
  );
};
export const Surprise = () => {
  return (
    <Scroller>
      <Cover />
    </Scroller>
  );
};
