import {
  createContext,
  PropsWithChildren,
  useCallback,
  useRef,
  useState,
} from "react";
import Scrollbar from "react-smooth-scrollbar";
import { Subject } from "rxjs";
import { ScrollStatus } from "smooth-scrollbar/interfaces";

export const ScrollerContext = createContext<{
  initScroller: any;
}>({
  initScroller: null,
});

function on<T>(event: Subject<T>, func: (e: T) => void) {
  event.subscribe({
    next: (payload: T) => {
      func(payload);
    },
  });
}

export const getPageIndex = (e: ScrollStatus) => {
  return Number(e.offset.y / window.innerHeight).toFixed(2);
};

export const relativeToPageIndex = (e: ScrollStatus, pageIndex = 0) => {
  return e.offset.y - window.innerHeight * pageIndex;
};
export const relativeToPageIndexWidth = (e: ScrollStatus, pageIndex = 0) => {
  return e.offset.y - window.innerWidth * pageIndex;
};
export const Scroller = ({
  children,
  onScroll,
}: PropsWithChildren<{ onScroll?: (e: ScrollStatus) => void }>) => {
  const scrollbar = useRef(null);
  const [_onScrollEvent] = useState(new Subject<any>());
  const [scroller] = useState({
    on: async (eventName: string, func: (a: any) => void) => {
      switch (eventName) {
        case "scroll":
          on(_onScrollEvent, func);
          break;
      }
    },
  });

  const handleScroll = (e: ScrollStatus) => {
    onScroll && onScroll(e);
    _onScrollEvent.next(e);
  };

  const initScroller = useCallback(
    (pageIndex?: number) => {
      if (!scrollbar || !scrollbar.current) return;
      return scroller;
    },

    [scrollbar]
  );

  return (
    <Scrollbar
      onScroll={handleScroll}
      alwaysShowTracks={false}
      ref={scrollbar}
      damping={0.1}
      // continuousScrolling={false}
      // thumbMinSize={1}
      // plugins={{
      //   overscroll: {
      //     effect: "glow",
      //   } as const,
      // }}
    >
      <ScrollerContext.Provider value={{ initScroller }}>
        <div style={{ height: "100vh" }}>{children}</div>
      </ScrollerContext.Provider>
    </Scrollbar>
  );
};
