import { PayloadAction } from "@reduxjs/toolkit";
import { call, delay, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";

async function getConfigurationApi(): Promise<any> {
  return await fetch("/api/configuration", {
    method: "GET",
  })
    .then((response) => {
      return response.json();
    })
    .catch((e) => console.error("et merde", e));
}
function* adminEmailRequest(): any {
  const response = yield call(getConfigurationApi);
  yield put(actions.email_success());
}
function* adminCodeRequest(
  action: PayloadAction<{ email: string; code: string }>
): any {
  let d = Number(localStorage.getItem("_d") || 0);
  d += 1000;
  localStorage.setItem("_d", d.toString());
  const response = yield call(getConfigurationApi);
  yield delay(d);
  if (action.payload.code === "code") yield put(actions.code_success());
  else yield put(actions.code_error());
}

export function* adminSaga() {
  yield takeLatest("ADMIN_EMAIL_REQUEST", adminEmailRequest);
  yield takeLatest("ADMIN_CODE_REQUEST", adminCodeRequest);
}
